<template>
    <div>
        <div id="container">
            <div id="contents">

                <div class="contents_body">
                    <div class="career-warp">
                        <span style="color:red;">picPreviewComp : 사진미리보기(사진첨부 클릭), quickMenuComp : 바로가기(화면 특정 영역으로 바로가기 - 우측하단 바로가기 열기) 샘플</span>
                        <br/>
                    </div>
                    <div class="career-warp">
                        <!--기본 인적사항-->
                        <div class="my-career" ref="ref_1">
                            <div class="tit" style="border-bottom:none;">기본 인적사항</div>

                            <!-- 사진 미리보기 -->
                            <picPreviewComp @pic-file="setPicFile" />
                            <!--// 사진 미리보기 -->

                            <div class="info">
                                <div class="user">
                                    <div class="name"><span>홍길동</span></div> <div class="general"><span>1978 / 남 / 43</span></div> <div class="period"><span>경력 총 00년 00개월</span></div>
                                </div>
                                <div class="detail">
                                    <div class="item">
                                        <div class="label"><span>전화번호</span></div>
                                        <div class="value"><span>00-0000-0000</span></div>
                                    </div>
                                    <div class="item">
                                        <div class="label"><span>휴대폰번호</span></div>
                                        <div class="value"><span>010-0000-0000</span></div>
                                    </div>
                                    <div class="item item-full">
                                        <div class="label"><span>이메일</span></div>
                                        <div class="value"><span>email@email.com</span></div>
                                    </div>
                                    <div class="item item-full">
                                        <div class="label"><span>주소</span></div>
                                        <div class="value"><span>서울시</span></div>
                                    </div>
                                    <div class="item item-full">
                                        <div class="label"><span>상벌사항</span></div>
                                        <div class="value"><span>ㅇㅇ 웹어워드 기획부분/우수상</span></div>
                                    </div>
                                </div>
                                <!--병역사항-->
                                <div class="military">
                                    <div class="label"><span>병역사항</span></div> 
                                    <div class="value"><span>현역 / 육군 / 통신 / 2001.03 ~ 2003.01</span></div>
                                </div>
                            </div>
                        </div><!--//기본인적사항-->
                        
                        <!--희망근무조건-->
                        <div class="my-career" ref="ref_2">
                            <div class="tit">희망 근무조건</div>
                            <div class="info">
                                <div class="Board" style="margin-top:0;">
                                    <table class="Board_type9">
                                        <colgroup>
                                            <col width="150">
                                            <col width="*">
                                            <col width="150">
                                            <col width="*">
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th><span>고용형태</span></th>
                                                <td><span>정규직</span></td>
                                                <th><span>희망연봉</span></th>
                                                <td><span>면접 후 결정</span></td>
                                            </tr>
                                            <tr>
                                                <th><span>희망 근무지역</span></th>
                                                <td><span>서울 전지역</span></td>
                                                <th><span>희망 근무시간</span></th>
                                                <td><span>월~금</span></td>
                                            </tr>
                                            <tr>
                                                <th><span>희망 업무내용</span></th>
                                                <td colspan="3"><span>공공, 금융, 교육</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div><!--//희망근무조건-->
                        
                        <!--자기소개서-->
                        <div class="my-career" ref="ref_3">
                            <div class="tit">자기소개</div>
                            <div class="info">
                                <div class="Board" style="margin-top:0;">
                                    <table class="Board_type9">
                                        <colgroup>
                                            <col width="150">
                                            <col width="*">
                                            <col width="150">
                                            <col width="*">
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th><span>기본소개</span></th>
                                                <td colspan="3">
                                                    <span>
                                                    앞으로 마스크를 쓰지 않은 승객은 버스나 택시 탑승이 제한된다. 버스나 택시 기사가 '노마스크' 승객의 탑승을 거부할 수 있어 이용 자체가 어려울 수도 있다.
                                                    신종 코로나바이러스 감염증(코로나19)이 지속해서 확산하자 생활 속 비말(침방울)을 통한 전염을 막기 위해 정부가 일부 지자체에서 시행 중인 마스크 착용 의무화 조치를 전국으로 확대한 것이다.
                                                    중앙재난안전대책본부는 버스나 택시, 철도 등 운수 종사자나 이용 승객 가운데 마스크 착용을 소홀히 하는 사례를 개선하기 위한 이 같은 내용의 '교통분야 방역 강화 방안'을 마련했다고 25일 밝혔다.
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th><span>특징점</span></th>
                                                <td colspan="3">
                                                    <span>
                                                    다만 마스크를 쓰지 않은 승객에 대해서는 직접적인 제재를 가하지 않는다.
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th><span>성격 장단점</span></th>
                                                <td colspan="3">
                                                    <span>
                                                    국토교통부 관계자는 "현행법상 마스크를 착용하지 않는 승객에 대한 직접적인 제재 규정이 없는 상황"이라면서 "운수 종사자가 마스크를 쓰지 않은 승객의 승차를 제한하도록 함으로써 마스크 미착용 문제를 풀려는 것"이라고 설명했다.
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th><span>지원동기</span></th>
                                                <td colspan="3">
                                                    <span>
                                                    정부는 철도와 도시 철도에 대해서는 승객의 승차 제한을 허용할 수 있도록 관련 기관에 유권 해석도 의뢰할 계획이다.
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th><span>입사 후 포부</span></th>
                                                <td colspan="3">
                                                    <span>
                                                    정부는 이와 함께 항공편에 대해서도 마스크 착용 조치를 강화하기로 했다.
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div><!--//자기소개서-->
                        
                        <!--직무,전문분야-->
                        <div class="my-career" ref="ref_4">
                            <div class="tit">직무분야 및 전문분야</div>
                            <div class="info">
                                <div class="Board" style="margin-top:0;">
                                    <table class="Board_type9">
                                        <colgroup>
                                            <col width="150">
                                            <col width="*">
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th><span>직부분야</span></th>
                                                <td><span>AA, PL, PM</span></td>
                                            </tr>
                                            <tr>
                                                <th><span>전문분야</span></th>
                                                <td>
                                                    <span>
                                                    금융, 보험 / 계정계 수신<br />
                                                    금융, 보험 / 계정계 수신
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div><!--//직무,전문분야-->							
                        
                        <!--학력-->
                        <div class="my-career" ref="ref_5">
                            <div class="tit">학력사항</div>
                            <div class="info">
                                <div class="Board" style="margin-top:0;">
                                    <table class="Board_type9">
                                        <colgroup>
                                            <col width="250">
                                            <col width="200">
                                            <col width="*">
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th><span>프로엔 대학원</span></th>
                                                <td><span>0000.02 ~ 0000.03</span></td>
                                                <td><span>경제학(석사)</span></td>
                                            </tr>
                                            <tr>
                                                <th><span>프로엔 전문대학</span></th>
                                                <td><span>0000.02 ~ 0000.03</span></td>
                                                <td><span>경영학</span></td>
                                            </tr>
                                            <tr>
                                                <th><span>프로엔 고등학교</span></th>
                                                <td><span>0000.02 ~ 0000.03</span></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div><!--//학력-->
                        
                        <!--교육-->
                        <div class="my-career" ref="ref_6">
                            <div class="tit">교육사항</div>
                            <div class="info">
                                <div class="Board" style="margin-top:0;">
                                    <table class="Board_type9">
                                        <colgroup>
                                            <col width="250">
                                            <col width="200">
                                            <col width="*">
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th><span>프로엔 프로그래밍아카데미</span></th>
                                                <td><span>0000.02 ~ 0000.03</span></td>
                                                <td><span>Java unit</span></td>
                                            </tr>
                                            <tr>
                                                <th><span>프로엔 프로그래밍아카데미</span></th>
                                                <td><span>0000.02 ~ 0000.03</span></td>
                                                <td><span>프레임워크</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div><!--//교육-->
                        
                        <!--자격증-->
                        <div class="my-career" ref="ref_7">
                            <div class="tit">자격증</div>
                            <div class="info">
                                <div class="Board" style="margin-top:0;">
                                    <table class="Board_type9">
                                        <colgroup>
                                            <col width="250">
                                            <col width="200">
                                            <col width="*">
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th><span>정보처리기사</span></th>
                                                <td><span>0000.02</span></td>
                                                <td><span>한국산업인력관리공단</span></td>
                                            </tr>
                                            <tr>
                                                <th><span>정보처리기술사</span></th>
                                                <td><span>0000.02</span></td>
                                                <td><span>한국산업인력관리공단</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div><!--//자격증-->
                        
                        <!--기술수준-->
                        <div class="my-career" ref="ref_8">
                            <div class="tit">기술수준</div>
                            <div class="info">
                                <div class="Board" style="margin-top:0;">
                                    <table class="Board_type9">
                                        <colgroup>
                                            <col width="250">
                                            <col width="*">
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <th><span>Python</span></th>
                                                <td><span>A</span></td>
                                            </tr>
                                            <tr>
                                                <th><span>Java</span></th>
                                                <td><span>A</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div><!--//기술수준-->
                        
                        <!--경력-->
                        <div class="my-career" ref="ref_9">
                            <div class="tit">재직경력</div>
                            <div class="info">
                                <div class="Board" style="margin-top:0;">
                                    <table class="Board_type10">
                                        <colgroup>
                                            <col width="300">
                                            <col width="*">
                                            <col width="160">
                                        </colgroup>
                                        <tbody>
                                            <!--반복시작-->
                                            <tr>
                                                <th rowspan="3">
                                                    <span class="date"><!--시작년월/종료년월-->0000.00 ~ 재직중</span><br>
                                                    <span class="time"><!--근무기간-->2년 6개월</span>
                                                </th>
                                                <td><span class="company"><!--회사명-->프로엔 솔루션</span> <span class="type"><!--기업구분/상장구분-->정부기관, 비상장</span></td>
                                                <td>
                                                    <div class="certify">
                                                        <span>KOSA 인증 경력</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><!--직위--><span>부장</span></td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2"><!--담당업무--><span>Front-En, HTML5, Publishing(반응형, 접근성), 웹기획, PM</span></td>
                                            </tr>
                                            <tr>
                                                <th colspan="3" class="line">&nbsp;</th>
                                            </tr>
                                            <!--반복끝-->	
                                            
                                            <tr>
                                                <th rowspan="3">
                                                    <span class="date"><!--시작년월/종료년월-->0000.00 ~ 0000.00</span><br>
                                                    <span class="time"><!--근무기간-->2년 6개월</span>
                                                </th>
                                                <td><span class="company"><!--회사명-->프로엔 솔루션</span> <span class="type"><!--기업구분/상장구분-->정부기관, 비상장</span></td>
                                                <td>
                                                    <div class="certify">
                                                        <span>건강보험 인증 경력</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><!--직위--><span>부장</span></td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2"><!--담당업무--><span>Front-En, HTML5, Publishing(반응형, 접근성), 웹기획, PM</span></td>
                                            </tr>
                                            <tr>
                                                <th colspan="3" class="line">&nbsp;</th>
                                            </tr>
                                            
                                            <tr>
                                                <th rowspan="3">
                                                    <span class="date"><!--시작년월/종료년월-->0000.00 ~ 0000.00</span><br>
                                                    <span class="time"><!--근무기간-->2년 6개월</span>
                                                </th>
                                                <td><span class="company"><!--회사명-->프로엔 솔루션</span> <span class="type"><!--기업구분/상장구분-->정부기관, 비상장</span></td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td><!--직위--><span>부장</span></td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2"><!--담당업무--><span>Front-En, HTML5, Publishing(반응형, 접근성), 웹기획, PM</span></td>
                                            </tr>
                                            <tr>
                                                <th colspan="3" class="line">&nbsp;</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div><!--//재직경력-->
                        
                        <!--프로젝트 경력-->
                        <div class="my-career" ref="ref_10">
                            <div class="tit">프로젝트 경력</div>
                            <div class="info">
                                <div class="Board" style="margin-top:0;">
                                    <table class="Board_type10">
                                        <colgroup>
                                            <col width="300">
                                            <col width="*">
                                            <col width="200">
                                        </colgroup>
                                        <tbody>
                                            <!--반복시작-->
                                            <tr>
                                                <th rowspan="5">
                                                    <span class="date"><!--시작년월/종료년월-->0000.00 ~ 진행중</span><br>
                                                    <span class="work"><!--근무사/근무형태/참여구분-->프로엔솔루션 / 정규직 / 내부개발</span><br>
                                                    <span class="time"><!--근무기간-->2년 6개월</span>
                                                </th>
                                                <td>
                                                    <span class="company"><!--프로젝트명-->프랜차이즈 유통 POS 및 본/지사 관리 시스템 개발</span> <span class="client"><!--고객사/고객사분류-->CJ / 일반대기업</span>
                                                </td>
                                                <td>
                                                    <div class="certify">
                                                        <span>KOSA 인증 프로젝트</span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><!--수행직무--><span>PM, PL</span></td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <!--수행업무-->
                                                    <span>본사 직영매장, 멀티매장, 프랜차이즈 업무관리 솔루션 개발</span>
                                                </td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <!--프로젝트 설명-->
                                                    <span>
                                                    프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 
                                                    프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <!--개발환경-->
                                                    <table class="dev">
                                                        <colgroup>
                                                            <col width="150">
                                                            <col width="150">
                                                            <col width="150">
                                                            <col width="150">
                                                            <col width="150">
                                                            <col width="150">
                                                        </colgroup>
                                                        <tbody>
                                                            <tr>
                                                                <th><span>개발환경기종</span></th>
                                                                <th><span>OS</span></th>
                                                                <th><span>언어</span></th>
                                                                <th><span>DBMS</span></th>
                                                                <th><span>TOOL</span></th>
                                                                <th><span>통신</span></th>
                                                            </tr>
                                                            <tr>
                                                                <td><span>VB</span></td>
                                                                <td><span>MS-SQL</span></td>
                                                                <td><span>Oracle</span></td>
                                                                <td><span>DBASE</span></td>
                                                                <td><span>MS-Access</span></td>
                                                                <td><span>TCP/IP</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <th colspan="3" class="line">&nbsp;</th>
                                            </tr>
                                            <!--반복끝-->
                                            <tr>
                                                <th rowspan="5">
                                                    <span class="date"><!--시작년월/종료년월-->0000.00 ~ 0000.00</span><br>
                                                    <span class="work"><!--근무사/근무형태/참여구분-->프로엔솔루션 / 정규직 / 내부개발</span><br>
                                                    <span class="time"><!--근무기간-->2년 6개월</span>
                                                </th>
                                                <td>
                                                    <span class="company"><!--프로젝트명-->프랜차이즈 유통 POS 및 본/지사 관리 시스템 개발</span> <span class="client"><!--고객사/고객사분류-->CJ / 일반대기업</span>
                                                </td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td><!--수행직무--><span>PM, PL</span></td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <!--수행업무-->
                                                    <span>
                                                    본사 직영매장, 멀티매장, 프랜차이즈 업무관리 솔루션 개발
                                                    </span>
                                                </td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <!--프로젝트 설명-->
                                                    <span>
                                                    프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 
                                                    프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <!--개발환경-->
                                                    <table class="dev">
                                                        <colgroup>
                                                            <col width="150">
                                                            <col width="150">
                                                            <col width="150">
                                                            <col width="150">
                                                            <col width="150">
                                                            <col width="150">
                                                        </colgroup>
                                                        <tbody>
                                                            <tr>
                                                                <th><span>개발환경기종</span></th>
                                                                <th><span>OS</span></th>
                                                                <th><span>언어</span></th>
                                                                <th><span>DBMS</span></th>
                                                                <th><span>TOOL</span></th>
                                                                <th><span>통신</span></th>
                                                            </tr>
                                                            <tr>
                                                                <td>VB</td>
                                                                <td>MS-SQL</td>
                                                                <td>Oracle</td>
                                                                <td>DBASE</td>
                                                                <td>MS-Access</td>
                                                                <td>TCP/IP</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <th colspan="3" class="line">&nbsp;</th>
                                            </tr>
                                            <tr>
                                                <th rowspan="5">
                                                    <span class="date"><!--시작년월/종료년월-->0000.00 ~ 0000.00</span><br>
                                                    <span class="work"><!--근무사/근무형태/참여구분-->프로엔솔루션 / 정규직 / 내부개발</span><br>
                                                    <span class="time"><!--근무기간-->기술경력 미포함</span>
                                                </th>
                                                <td>
                                                    <span class="company"><!--프로젝트명-->프랜차이즈 유통 POS 및 본/지사 관리 시스템 개발</span> <span class="client"><!--고객사/고객사분류-->CJ / 일반대기업</span>
                                                </td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td><!--수행직무--><span>PM, PL</span></td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <!--수행업무-->
                                                    <span>
                                                    본사 직영매장, 멀티매장, 프랜차이즈 업무관리 솔루션 개발
                                                    </span>
                                                </td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <!--프로젝트 설명-->
                                                    <span>
                                                    프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 
                                                    프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명, 프로젝트 설명
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <!--개발환경-->
                                                    <table class="dev">
                                                        <colgroup>
                                                            <col width="150">
                                                            <col width="150">
                                                            <col width="150">
                                                            <col width="150">
                                                            <col width="150">
                                                            <col width="150">
                                                        </colgroup>
                                                        <tbody>
                                                            <tr>
                                                                <th><span>개발환경기종</span></th>
                                                                <th><span>OS</span></th>
                                                                <th><span>언어</span></th>
                                                                <th><span>DBMS</span></th>
                                                                <th><span>TOOL</span></th>
                                                                <th><span>통신</span></th>
                                                            </tr>
                                                            <tr>
                                                                <td><span>VB</span></td>
                                                                <td><span>MS-SQL</span></td>
                                                                <td><span>Oracle</span></td>
                                                                <td><span>DBASE</span></td>
                                                                <td><span>MS-Access</span></td>
                                                                <td><span>TCP/IP</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <th colspan="3" class="line">&nbsp;</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div><!--//프로젝트 경력-->
                        
                        <!--증빙서류-->
                        <div class="my-career" ref="ref_11">
                            <div class="tit">증빙서류</div>
                            <div class="info">
                                <div class="Board" style="margin-top:0;">
                                    <table class="Board_type9">
                                        <colgroup>
                                            <col width="200">
                                            <col width="*">
                                        </colgroup>
                                        <tbody>
                                            <!--반복시작-->
                                            <tr>													
                                                <th><span>졸업증명서</span></th>
                                                <td><span>홍길동_졸업증명서.pdf</span></td>
                                            </tr>
                                            <!--반복끝-->
                                            <tr>													
                                                <th><span>자격증취득확인서</span></th>
                                                <td><span>홍길동_자격증취득확인서.pdf</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div><!--//증빙서류-->

                    </div>
                    <!--//기술신고서 항목 입력-->
                    
                </div><!--//contents_body-->
            </div><!--//contents-->
        </div><!--//container-->

        <!-- 화면에서 바로가기 -->
        <quickMenuComp :isOpenProps="false" :quickMenuInfo="quickMenuInfo" />
        <!--// 화면에서 바로가기  -->

    </div>
</template>

<script>

import picPreviewComp from "@/components/PicPreviewComp.vue";
import quickMenuComp from "@/components/QuickMenuComp.vue";

export default {

    components: {
        picPreviewComp,
        quickMenuComp
    },

    data() {

        return {
            //isQuickMenuOpen : false,    // 바로가기 표시여부

            quickMenuInfo : [
                { menuName : '인적사항',        menuRef : 'ref_1' },
                { menuName : '근무조건',        menuRef : 'ref_2' },
                { menuName : '자기소개',        menuRef : 'ref_3' },
                { menuName : '직무/전문분야',   menuRef : 'ref_4' },
                { menuName : '학력사항',        menuRef : 'ref_5' },
                { menuName : '교육사항',        menuRef : 'ref_6' },
                { menuName : '자격증',          menuRef : 'ref_7' },
                { menuName : '기술수준',        menuRef : 'ref_8' },
                { menuName : '재직경력',        menuRef : 'ref_9' },
                { menuName : '프로젝트 경력',   menuRef : 'ref_10' },
                { menuName : '증빙서류',        menuRef : 'ref_11' },
            ],

            picFile : Object,           // 사진파일
        };
    },
    methods: {

        // 사진 미리보기의 파일정보
        setPicFile(picFile) {
            //console.log('setPicFile', picFile);

            this.picFile = picFile;

            //console.log('this.setPicFile', this.picFile);
        },
    },
};
</script>

