<template>
    <div class="photo">
        <div class="img">
            <img v-if="localImg" width="180" height="220" :src="localImg"/>
        </div>
        <span class="btn" @click="addPicBtn">사진첨부</span>
        <input class="hide" type="file" name="uploadFile" ref="uploadFile" @change="previewImg" accept=".jpg,.gif,.png,.jpeg,.bmp,.tif"/>
    </div>
</template>

<script>
/**
 * 사진 미리보기 컴포넌트
 *      -> 파일선택 후 선택한 이미지를 미리보기로 보여주고 부모에게 파일정보를 emit 으로 전송
 * 
 * 사용법)
 *      props   : 없음
 *      emit    :  
 *                  pic-file : 파일정보 전송
 * 
 * 예시)
 *      <picPreviewComp @pic-file="setPicFile" />
 * 
 */
export default {

    props: {        
    },
    data() {
        return {
            localImg : '',           // 로컬 사진 (base64 데이터)
        }
    },

    beforeCreate() {
        // console.log('beforeCreate');
    },
    created() {
        // console.log('created');
    },
    beforeMount() {
        // console.log('beforeMount');
    },
    mounted() {
        //console.log('mounted');
    },
    beforeUpdate() {
        //console.log('beforeUpdate');
    },
    updated() {
        // console.log('update');
    },
    beforeDestroy() {
        // console.log('beforeDestroy');
    },
    destroyed() {
        // console.log('destroyed');
    },    

    methods : {
        addPicBtn() {
            this.$refs.uploadFile.click();
        },

        previewImg(event) {
            var files = event.target.files;
            
            if(files && files[0]) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.localImg = e.target.result;
                }
            
                reader.readAsDataURL(files[0]);

                this.$emit('pic-file', files[0]);
            }
        },
    }
    
}
</script>